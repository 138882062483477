#home-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

#home-content {
  margin: 0;
  margin-left: auto;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

img {
  cursor: pointer;
}

/* Huge Screens */
@media only screen and (min-width: 1500px) {
  img {
    height: 1000px;
  }
}

/* Laptops and small monitors */
@media only screen and (max-width: 1500px) {
  img {
    height: 700px;
  }
}

/* Mobile */
@media only screen and (max-width: 768px) {
  img {
    height: 400px;
  }
}
