.about-us-cards {
  display: flex;
  flex-direction: column;
}

.about-us-card {
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 5em;
  margin-top: 1.5em;
}

.about-us-card.right {
  flex-flow: row-reverse wrap;
}

.about-us-card img {
  height: 14em;
  width: 22em;
  object-fit: cover;
}

.about-us-card .content-container {
  width: 20em;
  display: flex;
  flex-direction: column;
  margin-left: 4em;
  justify-content: center;
}

.about-us-card.right .content-container {
  margin-right: 4em;
  margin-left: 0em;
}

.about-us-card p.content {
  color: #185945;
  margin-bottom: 0px;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  margin-top: 0;
}

.about-us-card p.sub-content {
  color: #d2c8b8;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  line-height: 30px;
}

.about-us-container {
  width: 100%;
}
.about-us-container h3 {
  margin-bottom: 1.5em;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  margin-top: 0;
  text-transform: none;
}

.videos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Tablet */

@media only screen and (max-width: 960px) {
  .about-us-card {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .about-us-card img {
    width: 100%;
    height: auto;
  }

  .page-body.large.wide {
    width: 100vw;
  }
  .about-us-card .content-container {
    width: 80vw;
    margin: 3em 0;
  }

  .about-us-card .content-container p {
    text-align: center;
  }

  .about-us-container h3 {
    font-weight: 600;
    line-height: 24px;
    font-size: 22px;
    width: 90vw;
  }
}

.videos-container {
  margin-top: 2em;
}

.video-card {
  margin-bottom: 1em;
}

@media only screen and (max-width: 768px) {
  .about-us-container {
    margin-top: 20px;
  }
}
