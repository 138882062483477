.date {
  height: 30em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
}

.wedding-event {
  height: fit-content;
  width: 100%;
  border-bottom: solid 1px #d2c8b8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wedding-event p {
  text-align: center;
  line-height: 40px;
}

.date h2 {
  font-family: "Playfair Display", serif;
  color: #185945;
  font-size: 5em;
}

#wedding-page {
  max-width: none;
  height: 100%;
}

#wedding-page.page-body {
  margin: 20px auto;
}

.wedding-events {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 800px;
  max-width: 1000px;
}

@media only screen and (max-width: 768px) {
  .wedding-events {
    min-width: 0px;
    max-width: 80vw;
  }
}
