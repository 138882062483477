.App {
  text-align: center;
  height: 100vh;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

header {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 64px;
  z-index: 5;
}

#app-header {
  position: fixed;
  top: 0;
  height: 68px;
  border-bottom: solid 1px #d2c8b8;
  z-index: 2;
  background-color: white;
}

.page-container {
  width: 100%;
  height: 100%;
}

.header-icon {
  color: #185945;
  transition: color 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  margin-right: 32px;
  cursor: pointer;
}

.header-icon:hover {
  color: #9a9490 !important;
}

footer#app-footer {
  height: 68px;
  border-top: solid 1px #d2c8b8;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer#app-footer.large {
  position: relative;
  z-index: 2;
}
footer#app-footer.small {
  position: fixed;
  bottom: 0;
}

.page-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 110vh;
  z-index: 1;
  margin: 40px auto;
  max-width: 700px;
}

.page-body.large {
  display: flex;
  height: fit-content;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding-bottom: 69px;
}

.page-body.small {
  height: fit-content;
}

h1 {
  font-family: "Playfair Display";
  color: #185945;
  margin: 0.4em auto 0.4em;
  font-size: 2.8em;
  font-weight: 300;
}

h2 {
  font-family: "Playfair Display", serif;
  text-transform: lowercase;
  font-size: 2.5em;
  font-weight: 300;
  color: #185945;
  margin: 0 0 0.3em;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #185945;
  margin-bottom: 3px;
  text-transform: uppercase;
  line-height: 45px;
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #185945;
  margin-bottom: 3px;
  margin-top: 40px;
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #717171;
  margin-bottom: 30px;
  font-size: 17px;
  text-align: left;
  line-height: 33px;
}

a {
  font-family: "Montserrat";
  text-decoration: none;
}

h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 200;
  color: #717171;
}

a.directions-link {
  display: block;
  width: 160px;
  padding: 1px;
  margin: 20px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  letter-spacing: 0px;
  color: #224338;
  text-align: center;
  white-space: normal;
  border-radius: 0;
  cursor: pointer;
  position: relative;
  background: linear-gradient(
    to right,
    rgb(220, 209, 193) 0%,
    rgb(184, 167, 137) 30%,
    rgb(211, 199, 180) 70%,
    rgb(220, 209, 193) 100%
  );
  -webkit-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.directions-link span {
  display: block;
  padding: 5px 10px;
  line-height: 1.5em;
  background-color: #ffffff;
}

#faq-page p {
  text-align: center;
}

.sub-header {
  height: 23em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.real-page-body {
  top: 69px;
  position: relative;
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .page-body {
    max-width: 80vw;
    margin: 15px auto;
  }
}
