.volcano-instructions li {
  display: list-item;
  margin-bottom: 0.2em;
  color: #717171;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  text-align: left;
  margin: 1em;
}

.page-body.large.volcano {
  align-items: flex-start;
}
