h2 {
  text-transform: none;
}

.page-body form {
  margin-top: 1em;
  width: 25em;
}

.sub-header.rsvp {
  height: 10em;
}

.rsvp-page h2 {
  text-transform: uppercase;
  margin: 1.5em 0;
}

.rsvp-page p {
  font-size: 1.4em;
  text-align: center;
}

.rsvp-page a {
  color: #185945;
}

@media only screen and (max-width: 768px) {
  .sub-header.rsvp {
    height: 6em;
  }
}
