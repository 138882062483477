.event-card {
  width: 1290px;
  height: 430px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 150px;
  margin-bottom: 40px;
}

.event-card-right {
  width: 1290px;
  height: 430px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  margin-right: 150px;
  margin-bottom: 40px;
}

.event-card img {
  height: 100%;
  width: 33%;
  object-fit: cover;
}

.event-card-right img {
  height: 100%;
  width: 33%;
  object-fit: cover;
}

.event-card .event-details {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
}

.event-card-right .event-details {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 40px;
}

.event-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1500px) {
  .event-card {
    width: 64em;
    height: 22em;
    margin-left: 10em;
  }
  .event-card-right {
    width: 64em;
    height: 22em;
    margin-right: 10em;
  }
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .event-card {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
    margin-bottom: 20px;
  }
  .event-card img {
    width: 100vw;
    position: relative;
  }

  .event-card img:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .event-card .event-details {
    width: 80vw;
    margin-right: 0;
  }
}
