/* Menu sits above the entire app and has a transparent background */

#menu-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 4;
  background-color: transparent;
  display: flex;
  flex-direction: row;
}

.image-container {
  width: 50%;
  height: 100vh;
  background-color: transparent;
}

.image {
  position: fixed;
  top: 100vh;
  left: 0;
  right: 50vw;
  height: 0%;
}

.image-enter {
  position: fixed;
  left: 0;
  right: 50vw;
  height: 100%;
  top: 100vh;
}

.image-enter-active {
  position: fixed;
  top: 0;
  left: 0;
  right: 50vw;
  height: 100%;
  transition: top 600ms;
}

.image-enter-done {
  position: fixed;
  top: 0;
  left: 0;
  right: 50vw;
  height: 100%;
}

.image-exit {
  position: fixed;
  top: 0;
  left: 0;
  right: 50vw;
  opacity: 1;
  height: 100%;
}

.image-exit-active {
  position: fixed;
  top: 100vh;
  left: 0;
  right: 50vw;
  opacity: 0;
  height: 100%;
  transition: opacity 600ms, top 600ms;
}
.image-exit-done {
  position: fixed;
  top: 100vh;
  left: 0;
  right: 50vw;
  opacity: 0;
  height: 0%;
}

.nav-menu-container {
  width: 50%;
  height: 100%;
}

.nav-menu {
  position: fixed;
  top: -100vh;
  left: 50vw;
  right: 50vw;
  height: 0%;
  background-color: #f0efed;
}

.nav-menu-enter {
  position: fixed;
  top: -100vh;
  left: 50vw;
  right: 0;
  height: 100%;
  background-color: #f0efed;
}

.nav-menu-enter-active {
  position: fixed;
  top: 0;
  left: 50vw;
  right: 0;
  height: 100%;
  background-color: #f0efed;
  transition: top 600ms;
}

.nav-menu-enter-done {
  position: fixed;
  top: 0;
  left: 50vw;
  right: 0;
  height: 100%;
  background-color: #f0efed;
}

.nav-menu-exit {
  position: fixed;
  top: 0;
  left: 50vw;
  right: 50vw;
  height: 100%;
  background-color: #f0efed;
  opacity: 1;
}

.nav-menu-exit-active {
  position: fixed;
  top: -100vw;
  height: 100%;
  left: 50vw;
  right: 0;
  background-color: #f0efed;
  opacity: 0;
  transition: opacity 800ms, top 600ms;
}

.nav-menu-exit-done {
  position: fixed;
  top: -100vw;
  height: 100%;
  left: 50vw;
  right: 0;
  background-color: #f0efed;
  opacity: 0;
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .image-container {
    display: none;
  }
  .nav-menu,
  .nav-menu-enter,
  .nav-menu-enter-active,
  .nav-menu-enter-done,
  .nav-menu-exit,
  .nav-menu-exit-active,
  .nav-menu-exit-done {
    left: 0;
  }
}

.header-icon.header-overlay {
  opacity: 1;
  position: fixed;
  right: 0px;
  z-index: 10;
  top: 15px;
  cursor: pointer;
}
