ul {
  margin-left: 20px;
}

li.nav-li {
  display: block;
  text-align: left;
  color: #224339;
  font-family: Roboto;
  font-weight: 100;
  margin: 30px;
}

#nav-menu a {
  color: #224339;
  font-weight: 300;
  text-decoration: none;
  padding-bottom: 10px;
  margin-bottom: 20px;
  max-width: 152px;
  opacity: 0;
  animation: fadeIn 2000ms 1;
  animation-fill-mode: forwards;
}

/* TODO: Find a way to not hard-code this */

#nav-menu a.nav-wedding {
  animation-delay: 80ms;
}
#nav-menu a.nav-events {
  animation-delay: 160ms;
}
#nav-menu a.nav-travel {
  animation-delay: 240ms;
}
#nav-menu a.nav-hotels {
  animation-delay: 320ms;
}
#nav-menu a.nav-antigua {
  animation-delay: 400ms;
}
#nav-menu a.nav-registry {
  animation-delay: 480ms;
}
#nav-menu a.nav-rsvp {
  animation-delay: 560ms;
}
#nav-menu a.nav-faqs {
  animation-delay: 640ms;
}
#nav-menu a.nav-about-us {
  animation-delay: 720ms;
}

#nav-menu a.selected {
  font-weight: 500;
}

#nav-menu {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

li.nav-li:after {
  content: "";
  display: block;
  margin: auto;
  height: 1px;
  background: transparent;
  margin-top: 0px;
  position: relative;
  width: 0%;
  top: 5px;
  border-radius: 100000%;
  transition: width 0.5s ease, background-color 0.5s ease;
}

li.nav-li:hover:after {
  width: 100%;
  background: #afa898;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
