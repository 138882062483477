.page-body.large.wide {
  width: 80vw;
  max-width: 1440px;
}

.hotels-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.event-hotel {
  width: 75%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #d2c8b8;
  padding-bottom: 30px;
}

.event-hotel img {
  width: 100%;
  height: auto;
}

.hotels-container h1 {
  margin: 0.4em auto;
}

.hotels-container h3 {
  text-transform: none;
  line-height: 0.3em;
}

.other-hotels {
  width: 100%;
  max-width: 900px;
}

.hotel-cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.hotel-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 20em;
}

.hotel-card img {
  width: 100%;
  height: auto;
}

/* for mobile */
@media only screen and (max-width: 768px) {
  .page-body.large.wide {
    width: 100vw;
    margin-top: 0;
  }

  .event-hotel {
    width: 100%;
  }

  .hotels-container p {
    margin: 0 1em;
  }

  .hotel-card {
    width: 100%;
  }

  .hotel-card img {
    height: 20em;
    object-fit: cover;
  }

  .hotels-container h3 {
    line-height: 2em;
  }
}

/* make all images stretch to 100vw */
/* get rid of margin above event-hotel img */
/* Make page body slightly wider */
